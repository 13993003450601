/* If you need to add @import statements, do so up here */

@import "jit-refresh.css"; /* triggers frontend rebuilds */

@layer base {
  body {
    letter-spacing: 0.015rem;
  }

  details {
    @apply relative;
  }

  details summary::-webkit-details-marker {
    display:none;
  }

  summary {
    @apply list-item list-none cursor-pointer;
  }

  a {
    @apply hover:opacity-90;
  }

  hr {
    @apply border-gray-200 dark:border-gray-800;
  }

  .prose h1, .prose h2, .prose h3, .prose h4, .prose h5, .prose h6,
  .prose-lg h1, .prose-lg h2, .prose-lg h3, .prose-lg h4, .prose-lg h5, .prose-lg h6 {
    @apply font-medium mb-3
  }

  .prose, .prose-lg > ul {
    @apply list-disc -mt-4 mb-8;
  }

  .prose, .prose-lg > ol {
    @apply list-decimal -mt-4 mb-8;
  }

  .prose, .prose-lg a {
    @apply underline;
  }

  .prose, .prose-lg p {
    @apply no-underline;
  }

  kbd {
    @apply px-2 text-sm py-0.5 rounded bg-gray-200 dark:bg-gray-700;
  }
}

@tailwind base;
@tailwind components;
@tailwind utilities;

@layer components {
  /* Typography */
  .text-primary {
    @apply text-gray-800 dark:text-white
  }

  .text-secondary {
    @apply text-neutral-600 dark:text-neutral-300
  }

  .text-muted {
    @apply text-gray-700/70 dark:text-gray-400;
  }

  .instrument-serif-regular {
    font-family: "Instrument Serif", serif;
    font-weight: 400;
    font-style: normal;
  }

  .instrument-serif-regular-italic {
    font-family: "Instrument Serif", serif;
    font-weight: 400;
    font-style: italic;
  }

  .money {
    @apply font-medium tabular-nums tracking-tight;
  }

  .form-input {
    @apply block w-full dark:bg-gray-800 rounded-md border-gray-300 dark:border-gray-900 shadow-sm focus:ring-0 focus:ring-offset-0 sm:text-sm;
  }

  .form-checkbox {
    @apply h-4 w-4 rounded border-gray-300 text-gray-800 focus:ring-gray-300;
  }

  /* Buttons */
  .btn {
    @apply px-4 py-2 text-sm rounded-md font-medium no-underline;
  }

  .btn-xl {
    @apply px-6 py-3 text-base md:text-lg rounded-full font-medium no-underline;
  }

  .btn-sm {
    @apply px-4 py-1 text-xs md:text-sm rounded-md font-medium no-underline;
  }

  .btn-primary {
    @apply bg-yellow-400 text-gray-800 hover:cursor-pointer;
  }

  .btn-secondary {
    @apply bg-gray-100 border border-gray-200/50 dark:border-gray-700/50 hover:bg-gray-200/50 text-gray-600 dark:bg-gray-800 dark:hover:bg-gray-800/60 dark:text-gray-100 hover:cursor-pointer;
  }

  .data-table {
    @apply w-full border-collapse;
  }

  .data-table thead tr th {
    @apply py-4 text-secondary font-medium uppercase text-xs tracking-wider;
  }

  .data-table thead tr th:first-child {
    @apply pl-4
  }

  .data-table thead tr th:last-child {
    @apply pr-4
  }

  table.data-table tbody tr td:first-child {
    @apply pl-4 sticky left-0
  }

  .data-table tbody tr td:last-child {
    @apply pr-4
  }

  .data-table tbody tr td {
    @apply py-2.5 border-t border-gray-100 dark:border-gray-800 tabular-nums;
  }

  .data-table tbody tr:nth-child(even) {
    @apply dark:bg-gray-800/30 bg-gray-100;
  }

  .data-table tbody tr:last-child td:first-child {
    @apply rounded-bl rounded-br-none;
  }

  .data-table tbody tr:last-child td:last-child {
    @apply rounded-br rounded-bl-none;
  }

  /*Popup*/
  .popup-menu {
    @apply absolute flex flex-col list-none gap-1 p-1 rounded-lg shadow-lg bg-white border border-gray-200/50 dark:border-gray-700/50 dark:bg-gray-800 z-50;
  }

  .popup-menu--item {
    @apply flex items-center rounded px-2 py-1 text-sm text-gray-700 dark:text-gray-300 hover:bg-gray-100 dark:hover:bg-gray-700/50;
  }

  .popup-menu--item-active {
    @apply bg-gray-100 dark:bg-gray-700;
  }

  .popup-menu--left {
    @apply right-0 mt-1;
  }

  .popup-menu--right {
    @apply left-0 mt-1;
  }

  .popup-menu--center {
    @apply left-1/2 transform -translate-x-1/2;
  }

  .popup-menu--top {
    @apply bottom-full mb-1;
  }

  .popup-menu--fit {
    @apply absolute -inset-x-full mt-1;
  }
}

